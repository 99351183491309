import React from 'react';
import { Link } from 'gatsby';

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="mb-2">
              {/* Logo */}
              <Link to="/" className="inline-block" aria-label="Cruip">
                <h6 className="text-gray-800 font-medium mb-2">Simple WP Dashboard Theme</h6>
              </Link>
            </div>
            <div className="text-sm text-gray-600">
              <Link to="#" className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">Terms</Link> · <Link to="#" className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">Privacy Policy</Link>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-4 lg:col-span-4">
            <h6 className="text-gray-800 font-medium mb-2">Other Plugins</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <a href="https://mywpdev.com/project/advanced-business-hours/" rel="noopener noreferrer" target="_blank" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Advanced Business Hours</a>
              </li>
              <li className="mb-2">
                <a href="https://mywpdev.com/project/advanced-music-widget/" rel="noopener noreferrer" target="_blank" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Advanced Music Widget</a>
              </li>                       
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-4 lg:col-span-4">
            <h6 className="text-gray-800 font-medium mb-2">Resources</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <a href="https://help.mywpdev.com/" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Documentation</a>
              </li>
              <li className="mb-2">
                <a href="https://help.mywpdev.com/article/tag/wordpress-dashboard-theme/" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Tutorials & Guides</a>
              </li>
              <li className="mb-2">
                <a href="https://help.mywpdev.com/" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Support Center</a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">

          {/* Social links */}
          

          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">Copyright © {(new Date().getFullYear())} All rights reserved.</div>

        </div>

      </div>
    </footer>
  );
}

export default Footer;
