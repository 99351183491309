import React, { useEffect } from 'react';
import AOS from 'aos';
//import '../../css/style.scss'

const Layout = props => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  return (
    <React.Fragment>
       {props.children}
    </React.Fragment>
  );
};

export default Layout;